import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import styled from 'styled-components'
import { Container, Row, Col } from "react-bootstrap";
import Image from 'react-bootstrap/Image'
import { LinkList } from './components/profile/linkList';
import { useAxiosGet } from './api/apiHook';
import { Preloader as LoadingSpinner, Oval } from "react-preloader-icon";
import { FaPhone } from 'react-icons/fa';
import { AiOutlineMail, AiOutlineShareAlt, AiOutlineWhatsApp } from 'react-icons/ai';
import { RWebShare } from 'react-web-share';
import { useParams } from 'react-router-dom';
import { LandingPage } from './landing/LandingPage';
import { ErrorPage } from './components/error/ErrorPage';
import { ImagesCarousel } from './components/image/ImageCarousel';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR, DEFAULT_PROFILE_IMG } from './DEFAULTS';
import { setHeaderInfo } from './HeaderUtil';
import { useLocation } from 'react-router-dom';
import { CookieConsent } from 'react-cookie-consent';
function App() {
  const { domain } = useParams()
  
  const { data: tenantInfo, error, loaded } = useAxiosGet(
    `tenants/findByDomain/`,
    { domain },
  );

  const isTenantDisabled = (tenantInfo) => tenantInfo?.name.startsWith("XXX_");
  
  const location = useLocation();

  setHeaderInfo(tenantInfo, location);
  
  const OptionIcon = styled.a`
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    border: none;
    color: ${(props) => props.color};
    :hover {
      color: ${(props) => props.color};
      filter: brightness(80%);
    }
  `;

  const iconOptionColor = tenantInfo?.settings?.descriptionTextColor || DEFAULT_TEXT_COLOR;
  const ICONS_SIZE = "1.8em";
  let phoneWith34 = tenantInfo?.phone;
  if (tenantInfo?.phone.length === 9) {
    phoneWith34 = `34${tenantInfo?.phone}`;
  };

  // console.log(error)
  // console.log(tenantInfo);
  return (
    <>
      { !domain && <LandingPage/> }
      { (domain && error || isTenantDisabled(tenantInfo)) && <ErrorPage/>}
      <div id="main" style={{
        color: iconOptionColor,
        background: tenantInfo?.settings?.backgroundImage ? `url(${tenantInfo?.settings?.backgroundImage}) no-repeat center center fixed` : tenantInfo?.settings?.backgroundColor || DEFAULT_BACKGROUND_COLOR, 
      }}>
      { !loaded && <div className="loadingSpinner"> <LoadingSpinner
          use={Oval}
          size={32}
          strokeWidth={8}
          strokeColor="#F0AD4E"
          duration={800}
        />  </div> }
      { loaded && tenantInfo &&
          <Container className="container">
            <Row className="profile">
              <Col>
                <Image roundedCircle src={`${tenantInfo.imageUrl || DEFAULT_PROFILE_IMG}` } className="profileImage"  alt="profile"/>
              </Col>
              <Row className="websiteName text-center">
                {tenantInfo?.name && (<span> {`@${tenantInfo.name}`} </span>)}
              </Row>
            </Row>
              <Row className="profileOptions justify-content-md-center">
                <Col xs="1" md="2" lg="3">
                </Col>
                <Col>
                <Row style={{ padding: "5px 0px"}}>
                  {tenantInfo.showPhoneNumber && <Col>
                    <OptionIcon color={iconOptionColor}  href={`tel:${tenantInfo?.phone.replace(/[+]/g, '')}`}>
                      <FaPhone size={ICONS_SIZE}/>
                      <p className="textWithIcon"> Llamar </p>
                    </OptionIcon>
                  </Col>}
                  {tenantInfo.showEmail && <Col>
                    <OptionIcon color={iconOptionColor}  href={`mailto:${tenantInfo?.email}`}>
                      <AiOutlineMail size={ICONS_SIZE}/>
                      <p className="textWithIcon"> Email </p>
                    </OptionIcon>  
                  </Col>}
                  {/* {tenantInfo.showWhatsapp && <Col>
                    <OptionIcon color={iconOptionColor}  href={`https://api.whatsapp.com/send?phone=${tenantInfo?.phone}`}>
                      <FaWhatsapp size={ICONS_SIZE}/>
                      <p className="textWithIcon"> Whatsapp </p>
                    </OptionIcon>
                  </Col>} */}
                  <Col>
                    <RWebShare
                      data={{
                        text: `Visita mi perfil en `,
                        url: tenantInfo?.domain === "cebrianasesores" ? "https://www.cebrianasesores.es/tarjetadigital/" : `https://www.tdsur.es/${tenantInfo.domain}`,
                        title: `Compartir perfil`,
                      }}
                      onClick={() => console.log("shared successfully!")}
                      sites={["facebook", "whatsapp", "telegram", "mail"]}
                    >
                      <OptionIcon href='#' color={iconOptionColor} >
                        <AiOutlineShareAlt size={ICONS_SIZE}/>
                        <p className="textWithIcon"> Compartir </p>
                      </OptionIcon>
                    </RWebShare> 
                  </Col>
                </Row>
                </Col>
                <Col xs="1" md="2" lg="3">
                </Col>
              </Row>
              <Row className="text-center">
                <span>
                  <div dangerouslySetInnerHTML={{ __html: tenantInfo?.description }} />
                </span>
              </Row>
                <LinkList tenantInfo={tenantInfo}></LinkList>
              { tenantInfo?.image1 && <Row>
                <Col xs="0" md="2" lg="3"></Col>
                <Col xs="12" md="8" lg="6">
                  <ImagesCarousel tenantInfo={tenantInfo}/>
                </Col>
                <Col xs="0" md="2" lg="3"></Col>
              </Row>}
              <Row>
                 <Col>
                  { tenantInfo.qr && <Image src={tenantInfo.qr} style={{ width: "300px", height:"300px"}}/> }
                 </Col>
              </Row>
              <a href={`https://api.whatsapp.com/send?phone=${phoneWith34}`}>
                <AiOutlineWhatsApp  className="float" />
              </a>
              
              { tenantInfo.reviewsLink && 
              (<a href={tenantInfo.reviewsLink}>
                <Image src="./gracias1.png" style={{ height:"50px"}}/>
                </a>)
              }
              <CookieConsent
                buttonText="ACEPTAR"
                cookieName="TDSURCOOKIE"
                declineButtonText="RECHAZAR"
                declineCookieValue="tdsur_declined"
                setDeclineCookie
                enableDeclineButton
                style={{
                  background: "black",
                  border: "white 2px"
                }}
                buttonStyle={{
                  background: "white",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Este sitio web usa cookies para mejorar su experiencia{" "}
                <span style={{ fontSize: "12px" }}>Usted puede aceptar o rechazar su uso en cualquier momento de acuerdo con nuestra política de privacidad y cookies</span>
              </CookieConsent>
            </Container>
            
        }
      </div>
    </>
  );
};

export default App;
