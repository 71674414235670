import React from 'react';
import { Button, Card, Container, Image, Navbar, Nav, Row, Col, Form } from 'react-bootstrap';
import { FaAngellist } from "react-icons/fa";
import './css/landing.css';


export const LandingPage = () => {
    const currentYear = new Date().getFullYear();

    return (
      <Container id="landing-page" fluid>
        {/* Navbar */}
        <Navbar bg="light" expand="lg" className="navbar w-100">
        <Container fluid>
          <Navbar.Brand href="#">
            <Image className="logo" src='./landing/logo.png' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#clients">Clientes</Nav.Link>
              <Nav.Link href="#contact">Contacto</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  
        {/* Subtitle and Paragraph */}
        <main className="py-5 main-content">

        <Container className="text-center mt-5" id="clients">
           <Row>
                <Col>
                <h1>Toda tu información, en un solo lugar</h1>
                <a 
                  href="https://tdsur.es/difactory" 
                  class="btn btn-primary btn-lg d-inline-flex align-items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="fa fa-id-card me-2"></i> Ver Tarjeta Digital
                </a>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                <Image src='./landing/qr-imagen.png' style={{ padding: "0", width: "200px" }}></Image>
                </Col>
            </Row>
        </Container>
  
        <Container className="text-center mt-5">
            <Row className="justify-content-center">
                    <Card style={{ width: '18rem', marginTop: "10px" }}>
                        <Card.Img variant="top" src="./landing/practico1.png" />
                        <Card.Body>
                            <Card.Title>Tarjeta de presentación</Card.Title>
                            <Card.Text>
                                Es la forma más económica y eficaz de presentarse ante potenciales clientes o colaboradores. A través de nuestra tarjeta podrás mostrar tu mejor presentación
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '18rem', marginLeft: "35px", marginRight: "35px", marginTop: "10px" }}>
                        <Card.Img variant="top" src="./landing/practico2.png" />
                        <Card.Body>
                            <Card.Title>Tu tarjeta digital</Card.Title>
                            <Card.Text>
                                Benefíciate del networking y marketing digital que ofrece nuestra tarjeta para establecer relaciones comerciales, y fidelizar a tus clientes
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card style={{ width: '18rem', marginTop: "10px" }}>
                        <Card.Img variant="top" src="./landing/practico3.png" />
                        <Card.Body>
                            <Card.Title>Excelentes resultados</Card.Title>
                            <Card.Text>
                                Con nuestra estrategia de marketing integrada en nuestra tarjeta digital, aumentarás tu presencia positiva en la red
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
        </Container>
        {/* Images with Text */}
        <Container className="image-section mt-5">
        <h3 className="section-header">
          <FaAngellist /> Algunos de nuestros clientes
        </h3>
        <br />
        <Row>
          <Col md={4} className="text-center">
            <a href="https://tdsur.es/estetydent">
              <img
                src="landing/estety-qr.png"
                alt="Estetydent"
                className="image qr-landing-image"
              />
            </a>
            <p className="image-text">estetydent</p>
          </Col>
          <Col md={4} className="text-center">
            <a href="https://www.tdsur.es/instrumentosaljarafe">
              <img
                src="landing/instrumentos-qr.png"
                alt="Imagen 2"
                className="image qr-landing-image"
              />
            </a>
            <p className="image-text">Instrumentos Aljarafe</p>
          </Col>
          <Col md={4} className="text-center">
            <a href="https://tdsur.es/difactory">
              <img
                src="landing/difactory-qr.jpeg"
                alt="Imagen 3"
                className="image qr-landing-image"
              />
            </a>
            <p className="image-text">Difactory</p>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="text-center">
            <a href="https://tdsur.es/berro">
              <img
                src="landing/berro-qr.png"
                alt="Imagen 4"
                className="image qr-landing-image"
              />
            </a>
            <p className="image-text">Berro</p>
          </Col>
          <Col md={4} className="text-center">
            <a href="https://tdsur.es/woodcousins">
              <img
                src="landing/wood-qr.png"
                alt="Imagen 5"
                className="image qr-landing-image"
              />
            </a>
            <p className="image-text">Wood Cousins</p>
          </Col>
          <Col md={4} className="text-center">
            <a href="https://tdsur.es/heymaque">
              <img
                src="landing/hey-qr.png"
                alt="Imagen 6"
                className="image qr-landing-image"
              />
            </a>
            <p className="image-text">Heymaque</p>
          </Col>
        </Row>
        </Container>

              {/* Contact Section */}
      <Container id="contact" className="mt-5">
        <h3 className="text-center">Contáctanos</h3>
        <p className="text-center">Rellena el formulario para ponerte en contacto con nosotros:</p>
        <Row className="justify-content-center">
          <Col md={6}>
            <Form
              action="mailto:myguelruizb@gmail.com"
              method="POST"
              enctype="text/plain"
            >
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Nombre</Form.Label>
                <Form.Control type="text" placeholder="Tu nombre" name="name" required />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Tu correo electrónico" name="email" required />
              </Form.Group>
              <Form.Group className="mb-3" controlId="message">
                <Form.Label>Mensaje</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Tu mensaje" name="message" required />
              </Form.Group>
              <Button variant="primary" type="submit">
                Enviar
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
        </main>
        
       {/* Footer */}
       <footer className="bg-light text-center py-3 mt-5">
        <Container fluid>
          <p className="mb-0">
            &copy; {currentYear} tdsur.es.  Todos los derechos reservados.
          </p>
        </Container>
      </footer>
      </Container>
    );
  };
  
